import React from 'react'
import './About.css'

function Projects() {
    return (
        <div className='about-container'>
            <div className='about-content'>
            <h2>Who I Am</h2>
            </div>
        </div>

    )
}

export default Projects;
