import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';


function Navbar() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return (
        <>
          <nav className="navbar">
              <div className="navbar-container">
                <div className="logo">Amanda Mock</div>
                    <div className="menu-container">
                        <div className='menu-icon' onClick={ handleClick }>
                            <i className={ click ? 'fas fa-times' : 'fas fa-bars' } />
                        </div>
                        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                            <li className='nav-item'>
                                <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                About     
                                </Link> 
                            </li>
                            <li className='nav-item'>
                                <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                Work With Me     
                                </Link> 
                            </li>
                            <li className='nav-item'>
                                <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                Contact     
                                </Link> 
                            </li>
                        </ul>
                    </div>
              </div>
          </nav>
        </>
    )
}

export default Navbar
